import React, { useState, useEffect } from 'react';
import { sendLoginLink } from '../utils/loginUtils';
import '../css/Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [installPrompt, setInstallPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later
      setInstallPrompt(e);
      setShowInstallButton(true);
    });
  }, []);

  const handleInstall = async () => {
    if (!installPrompt) return;

    // Show the prompt
    installPrompt.prompt();

    // Wait for the user to respond to the prompt
    const { outcome } = await installPrompt.userChoice;
    if (outcome === 'accepted') {
      setShowInstallButton(false);
    }
    // Clear the saved prompt since it can't be used again
    setInstallPrompt(null);
  };

  const handleSendLink = async () => {
    try {
      const message = await sendLoginLink(email);
      alert(message);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="image-section">
        <img src="/flipped_doctor_image.webp" alt="NoteSmart" />
      </div>

      <div className="form-section">
        <h1>Welcome to Noteician</h1>
        <p>Create patient notes the smart way.</p>

        {showInstallButton && (
          <button
            className="install-button"
            onClick={handleInstall}
          >
            Install Noteician App
          </button>
        )}

        <div className="input-container">
          <label>
            Email:
            <input
              type="email"
              placeholder="email@provider.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setShowTooltip(true)}
              onBlur={() => setShowTooltip(false)}
            />
          </label>
          {showTooltip && (
            <div className="tooltip">
              Enter your email to receive a magic link for signing in or signing up.
            </div>
          )}
        </div>
        <button onClick={handleSendLink}>Send Login/Sign-Up Link</button>
        <button className="home-button" onClick={() => window.location.href = 'https://www.noteician.com'}>
          Home
        </button>
      </div>
    </div>
  );
}

export default Login;