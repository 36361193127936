import React, { useState } from 'react';
import '../css/BottomLeftNoteTaker.css';
import { startRecording, pauseRecording, resumeRecording, stopRecording } from '../utils/recordNoteUtil';
import { useNote } from '../context/NoteContext';

function BottomLeftNoteTaker() {
  const { setLatestNote, addLoadingNote } = useNote();
  const [activeButton, setActiveButton] = useState(null);


  const buttonConfig = [
    { label: 'Start Note', action: startRecording, id: 'start' },
    { label: 'Pause Note', action: pauseRecording, id: 'pause' },
    { label: 'Resume Note', action: resumeRecording, id: 'resume' },
  ];

  const handleStop = () => {
    // Add loading indicator in the document viewer and get the recording ID
    const recordingId = addLoadingNote();
    
    // Stop the recording and wait for the note to come back, passing the recording ID
    stopRecording(setLatestNote, recordingId);
    setActiveButton(null); // Reset to default state immediately
  };

  return (
    <div className="note-taker-container">
      <h2>Note Taker</h2>

      <div className="timer-container">
        <span id="timer">00:00:00</span>
      </div>
      <div className="button-grid">
        {buttonConfig.map(({ label, action, id }) => (
          <button
            key={id}
            className={activeButton === id ? 'active' : ''}
            onClick={() => {
              if (id === 'start') {
                const shouldStart = window.confirm('Please ensure you have patient consent before continuing.');
                if (shouldStart) {
                  action();
                  setActiveButton(id);
                }
                // If they cancel, we do nothing and the button stays inactive
              } else {
                action();
                setActiveButton(id);
              }
            }}
          >
            {label}
          </button>
        ))}
        <button onClick={handleStop}>Stop Note</button>
      </div>
    </div>
  );
}

export default BottomLeftNoteTaker;