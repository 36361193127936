import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the context
const NoteContext = createContext();

// Custom hook to use the NoteContext
export const useNote = () => useContext(NoteContext);

// Provider component that will wrap around the app
export const NoteProvider = ({ children }) => {
  const [latestNote, setLatestNote] = useState(null);
  const [loadingNotes, setLoadingNotes] = useState([]);
  const [lastRecordingId, setLastRecordingId] = useState(null);
  // Track which notes have been received for each recording session
  const [receivedNotes, setReceivedNotes] = useState({});

  // Add a loading note entry when requested
  const addLoadingNote = () => {
    // Create a unique ID for the recording session
    const recordingId = Date.now().toString();
    
    // Create two loading note entries (one for transcript, one for patient note)
    const newLoadingNotes = [
      { 
        id: `${recordingId}-transcript`, 
        recordingId: recordingId,
        type: 'Transcript', 
        timestamp: new Date().toISOString() 
      },
      { 
        id: `${recordingId}-note`, 
        recordingId: recordingId,
        type: 'Patient Note', 
        timestamp: new Date().toISOString() 
      }
    ];
    
    setLoadingNotes(prev => [...prev, ...newLoadingNotes]);
    setLastRecordingId(recordingId);
    
    // Initialize tracking for this recording session
    setReceivedNotes(prev => ({
      ...prev,
      [recordingId]: { 
        Transcript: false,
        'Patient Note': false
      }
    }));
    
    // Automatically remove loading notes after 60 seconds if they haven't been removed yet
    setTimeout(() => {
      setLoadingNotes(prev => prev.filter(note => 
        note.recordingId !== recordingId
      ));
      
      // Clean up the tracking for this recording
      setReceivedNotes(prev => {
        const newState = { ...prev };
        delete newState[recordingId];
        return newState;
      });
    }, 60000);
    
    return recordingId;
  };

  // Mark a note type as received for a specific recording session
  const markNoteReceived = (recordingId, type) => {
    if (!recordingId) return;
    
    setReceivedNotes(prev => {
      // If we don't have tracking for this recording ID, create it
      if (!prev[recordingId]) {
        return {
          ...prev,
          [recordingId]: { 
            Transcript: type === 'Transcript',
            'Patient Note': type === 'Patient Note'
          }
        };
      }
      
      // Update the existing tracking
      return {
        ...prev,
        [recordingId]: {
          ...prev[recordingId],
          [type]: true
        }
      };
    });
    
    // Check if all expected notes for this recording have been received
    setTimeout(() => {
      checkAndRemoveLoadingNotes(recordingId);
    }, 100); // Small delay to ensure state updates
  };
  
  // Check if all notes have been received for a recording and remove loading notes if so
  const checkAndRemoveLoadingNotes = (recordingId) => {
    setReceivedNotes(prev => {
      const recordingStatus = prev[recordingId];
      
      // If all notes have been received, remove the loading indicators
      if (recordingStatus && recordingStatus['Transcript'] && recordingStatus['Patient Note']) {
        setLoadingNotes(prevLoadingNotes => 
          prevLoadingNotes.filter(note => note.recordingId !== recordingId)
        );
        
        // Clean up tracking
        const newState = { ...prev };
        delete newState[recordingId];
        return newState;
      }
      
      return prev;
    });
  };

  // Remove loading note by recording ID and type - now marks it as received but doesn't remove it yet
  const removeLoadingNoteByRecordingId = (recordingId, type) => {
    if (!recordingId) return;
    
    // Mark this note type as received for this recording
    markNoteReceived(recordingId, type);
  };

  // For backwards compatibility - remove oldest loading note of specified type
  const removeLoadingNote = (type) => {
    if (lastRecordingId) {
      // Try to mark note as received for the last recording first
      markNoteReceived(lastRecordingId, type);
    } else {
      // Fall back to original behavior if no recordingId is available
      setLoadingNotes(prev => {
        // Find the oldest loading note of the specified type
        const oldestLoadingNote = [...prev]
          .filter(note => note.type === type)
          .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))[0];
        
        // If found, remove it from the loading notes
        if (oldestLoadingNote) {
          return prev.filter(note => note.id !== oldestLoadingNote.id);
        }
        
        return prev;
      });
    }
  };

  // Enhanced setLatestNote to handle recording ID
  const enhancedSetLatestNote = (note, recordingId) => {
    // First, update the latest note
    setLatestNote(note);
    
    // If we have a recording ID, try to determine the type and mark it as received
    if (recordingId) {
      const type = note && typeof note === 'string' && note.trim().startsWith('# Transcript') 
        ? 'Transcript' 
        : 'Patient Note';
      markNoteReceived(recordingId, type);
    }
  };

  return (
    <NoteContext.Provider value={{ 
      latestNote, 
      setLatestNote: enhancedSetLatestNote,
      loadingNotes,
      addLoadingNote,
      removeLoadingNote,
      removeLoadingNoteByRecordingId,
      lastRecordingId
    }}>
      {children}
    </NoteContext.Provider>
  );
};