import React, { useState, useEffect, useRef } from 'react';
import { fetchUserNotes } from '../utils/noteFetchUtils'; // Import the utility
import { useNote } from '../context/NoteContext'; // To update the note context
import '../css/TopLeftNoteViewer.css';
import { FaSync, FaSpinner } from 'react-icons/fa'; // Import icons

function TopLeftNoteViewer() {
  const [notes, setNotes] = useState([]); // Store fetched notes
  const { setLatestNote, loadingNotes, removeLoadingNote, removeLoadingNoteByRecordingId, lastRecordingId } = useNote(); // Get the context and loading state
  const [isRefreshing, setIsRefreshing] = useState(false);
  const processedNoteIds = useRef(new Set()); // Track which notes we've already processed
  
  // Use a faster polling rate when a recording has just stopped
  const [fastPolling, setFastPolling] = useState(false);
  
  // Track when the last recording ID changes
  useEffect(() => {
    if (lastRecordingId) {
      // When a new recording is stopped, enable fast polling
      setFastPolling(true);
      
      // Reset to normal polling after 30 seconds
      const timerId = setTimeout(() => {
        setFastPolling(false);
      }, 30000);
      
      return () => clearTimeout(timerId);
    }
  }, [lastRecordingId]);

  // Function to fetch and update notes
  const fetchAndSetNotes = async () => {
    if (isRefreshing) return; // Prevent concurrent fetches
    
    setIsRefreshing(true);
    try {
      const fetchedNotes = await fetchUserNotes(); // Fetch the notes from the server
      setNotes(fetchedNotes);
      
      // When new notes arrive, check if we need to remove loading indicators
      if (fetchedNotes && fetchedNotes.length > 0) {
        // Check for new notes we haven't processed yet
        fetchedNotes.forEach(note => {
          // Skip notes we've already processed
          if (processedNoteIds.current.has(note.id)) return;
          
          const type = getDocumentType(note.note);
          
          // If the note has a recordingId, use that for precise removal
          if (note.recordingId) {
            removeLoadingNoteByRecordingId(note.recordingId, type);
          } else {
            // Fall back to the old method
            removeLoadingNote(type);
          }
          
          // Mark this note as processed
          processedNoteIds.current.add(note.id);
        });
      }
    } catch (error) {
      console.error('Failed to fetch notes:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  // Set up initial fetch and interval-based refresh on component mount
  useEffect(() => {
    fetchAndSetNotes(); // Fetch notes on initial render
    
    // Dynamic polling interval - faster after a recording stops, slower otherwise
    const intervalId = setInterval(() => {
      const pollingInterval = fastPolling ? 2000 : 8000; // 2s when fast polling, 8s normally
      fetchAndSetNotes(); // Fetch notes at the determined interval
    }, fastPolling ? 2000 : 8000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [fastPolling]); // Recreate the interval when fastPolling changes

  // Function to handle clicking on a note row
  const handleNoteClick = (note) => {
    if (note.isLoading) return; // Don't do anything if it's a loading note
    setLatestNote(note.note); // Update the context with the selected note text, not the entire object
  };

  // Utility to get document type
  const getDocumentType = (text) => {
    if (typeof text === 'string' && text.trim().startsWith('# Transcript')) {
      return 'Transcript';
    }
    return 'Patient Note';
  };

  // Combine real notes and loading notes for display
  const allNotes = [
    ...notes.map(note => ({
      ...note,
      isLoading: false,
      type: getDocumentType(note.note),
    })),
    ...loadingNotes.map(loadingNote => ({
      id: loadingNote.id,
      recordingId: loadingNote.recordingId, // Include recordingId for proper tracking
      type: loadingNote.type,
      created_at: loadingNote.timestamp,
      isLoading: true,
    }))
  ];

  // Sort all notes by timestamp, with most recent first
  const sortedNotes = allNotes.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  // Manual refresh handler
  const handleManualRefresh = () => {
    fetchAndSetNotes();
  };

  return (
    <div className="top-left-note-viewer-container">
      <div className="top-left-header">
        <h2>Document Viewer</h2>

        {/* Refresh icon with tooltip */}
        <div className="refresh-icon-container" onClick={handleManualRefresh}>
          <FaSync className={`refresh-icon ${isRefreshing ? 'spinning' : ''}`} />
          <span className="tooltip-text">Refresh Documents</span>
        </div>
      </div>

      <div className="notes-container">
        <table>
          <thead>
            <tr>
              <th>Doc</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {sortedNotes.map((note, index) => (
              <tr 
                key={note.id || index} 
                onClick={() => handleNoteClick(note)}
                className={note.isLoading ? 'loading-row' : ''}
              >
                <td>{note.type}</td>
                <td>
                  {note.isLoading ? (
                    <div className="loading-spinner">
                      <FaSpinner className="spinning" /> 
                      <span>Processing...</span>
                    </div>
                  ) : (
                    new Date(note.created_at).toLocaleString()
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TopLeftNoteViewer;