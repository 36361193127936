// Function to fetch all notes for the current user
export const fetchUserNotes = async () => {
  try {
    // Add a cache-busting query parameter to prevent browser caching
    const cacheBuster = new Date().getTime();
    
    const response = await fetch(`/get-all-notes?_=${cacheBuster}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      },
      credentials: 'include', // Ensures cookies are sent with the request
    });

    if (!response.ok) {
      throw new Error('Failed to fetch notes');
    }

    const notes = await response.json();
    
    // Process notes to add recordingId if it's in the metadata
    // This is in case the backend doesn't explicitly include it
    const processedNotes = notes.map(note => {
      if (!note.recordingId && note.metadata && note.metadata.recordingId) {
        return { ...note, recordingId: note.metadata.recordingId };
      }
      return note;
    });
    
    return processedNotes;
  } catch (error) {
    console.error('Error fetching notes:', error);
    throw error;
  }
};