import React from 'react';
import '../css/TierModal.css'; // Import the CSS for the modal styling

function TierModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="tier-modal-overlay">
      <div className="tier-modal-content">
        <h2>Choose Your Plan</h2>
        <div className="tier-options-container">
          {/* Free Tier */}
          <div className="tier-card free-tier">
            <h3>Free</h3>
            <p>Price: £0</p>
            <p>Start with 14 days of unlimited note-taking when you sign up.</p>
            <ul>
              <li>No commitment</li>
              <li>Get a feel of our platform</li>
            </ul>
          </div>

          {/* Single User Tier */}
          <div className="tier-card single-user-tier">
            <h3>Individual Tier</h3>
            <p>Price: £30/month</p>
            <p>Price: £324/year (10% discount)</p>
            <ul>
              <li>Up to 150 note-taking hours per month</li>
              <li>Access to automated letter writing</li>
              <li>Ideal for individual users</li>
              <li>Email support included</li>
              <li>Free microphone included in yearly purchase!</li>
            </ul>
            <button className="subscribe-button" onClick={() => onClose() || window.dispatchEvent(new CustomEvent('openPaymentsModal', { detail: { tierType: 'individual' } }))}>
              Subscribe Now
            </button>
          </div>

          {/* Practice Tier */}
          <div className="tier-card practice-tier">
            <h3>Practice Tier</h3>
            <p>Price: £110/month</p>
            <p>Price: £1,188/year (10% discount)</p>
            <ul>
              <li>Up to 600 note-taking hours per month</li>
              <li>Access to automated letter writing</li>
              <li>Manage up to 4 individual members within your organization</li>
              <li>Priority support included</li>
              <li>4 free microphones included in yearly purchase!</li>
            </ul>
            <button className="subscribe-button" onClick={() => onClose() || window.dispatchEvent(new CustomEvent('openPaymentsModal', { detail: { tierType: 'practice' } }))}>
              Subscribe Now
            </button>
          </div>

          {/* Support Info Panel */}
          <div className="tier-card support-info">
            <h3>Need Assistance or Want to Customize Your Plan?</h3>
            <p>
              For queries about our tiers or assistance with upgrading, downgrading,
              or customizing your subscription, feel free to contact us at
              <strong> founders@noteician.com</strong>.
            </p>
            <p>We're here to help you make the most of Noteician!</p>
          </div>
        </div>



        <button className="close-modal-button-tier" onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default TierModal;
